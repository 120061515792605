<template>
  <v-container>
   <div
      class="d-flex"
    >
      <div :class="[`text-h4`, `mb-5`]"> {{ $route.params.id }} </div>
      <div class="ml-auto mt-2">
        <v-btn
          small
          icon
          @click="isJson = !isJson"
        >
          <v-icon>mdi-code-json</v-icon>
        </v-btn>
      </div>
    </div>
    <div>
      <fiware-subscription-form v-if="!isJson && check" :subscription="subscriptionForm" :id="$route.params.id" ref="subscription"></fiware-subscription-form>
      <v-jsoneditor v-if="isJson" v-model="filterSubscriptionForm" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor>
      <confirm-dialog v-model="isCancel" type="cancle" :title="$route.params.id" @confirm="$router.go(-1)"></confirm-dialog>
    </div>
    <div
      class="d-flex mt-6"
    >
      <v-btn
        class="ml-auto mx-2"
        depressed
        small
        color="success"
        @click="$refs.subscription.updateSubscription()"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Update
      </v-btn>
      <v-btn
        class="mx2"
        small
        depressed
        color="warning"
        @click="isCancel = true"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancel
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import api from '@/services/api'

var subscription = {
  description: null,
  subject: {
    entities: [{
      idPattern: null,
      type: null
    }],
    condition: {
      attrs: [],
      inputValue: null,
      expression: [
        {
          key: null,
          value: null
        }
      ]
    }
  },
  notification: {
    protocol: 'http',
    http: {
      url: null,
      headers: [
        {
          key: null,
          value: null
        }
      ],
      qs: [
        {
          key: null,
          value: null
        }
      ],
      method: null,
      payload: null
    },
    attrsFormat: null,
    attrs: {
      values: [],
      inputValue: null
    },
    timesSent: null,
    lastNoti: null,
    lastFail: null,
    lastSuccess: null
  },
  expires: null,
  throttling: null,
  status: null
}

Object.freeze(subscription)

export default {
  name: 'FiwareSubscribeDetail',
  components: {
    FiwareSubscriptionForm: () => import('@/components/fiware/FiwareSubscriptionForm'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  data: () => ({
    isJson: false,
    isCancel: false,
    subscriptionForm: {},
    check: false
  }),
  methods: {
    fetchSubscription: function () {
      api.subscriptionGET(`/f/v2/subscriptions/${this.$route.params.id}`, this.$store.state.firewareServices, '/test').then((response) => {
        response.json().then((json) => {
          // console.log(json)
          // json.forEach(obj => {
          //   this.entityItems.push({ name: obj.id, icon: 'mdi-desktop-tower' })
          // })
          this.subscriptionForm = this.convertSubscriptionToForm(json)
          this.check = true
        })
      })
    },
    convertSubscriptionToForm: function (subsc) {
      const subscribe = JSON.parse(JSON.stringify(subscription))
      if (subsc.description) subscribe.description = subsc.description
      if (subsc.subject && subsc.subject.entities) subscribe.subject.entities = [...subsc.subject.entities, { idPattern: null, type: null }]
      if (subsc.subject && subsc.subject.condition && subsc.subject.condition.attrs) subscribe.subject.condition.attrs = subsc.subject.condition.attrs
      if (subsc.subject && subsc.subject.condition && subsc.subject.condition.expression) {
        let expression = []
        for (const [key, value] of Object.entries(subsc.subject.condition.expression)) {
          expression.push({ key: key, value: value })
        }
        expression.push({ key: null, value: null })
        subscribe.subject.condition.expression = expression
      }
      if (subsc.notification && subsc.notification.http) {
        subscribe.notification.protocol = 'http'
        if (subsc.notification.http.url) {
          subscribe.notification.http.url = subsc.notification.http.url
        }
      }
      if (subsc.notification && subsc.notification.httpCustom) {
        subscribe.notification.protocol = 'httpCustom'
        if (subsc.notification.httpCustom.url) {
          subscribe.notification.http.url = subsc.notification.httpCustom.url
        }
        if (subsc.notification.httpCustom.headers) {
          let headers = []
          for (const [key, value] of Object.entries(subsc.notification.httpCustom.headers)) {
            headers.push({ key: key, value: value })
          }
          headers.push({ key: null, value: null })
          subscribe.notification.http.headers = headers
        }
        if (subsc.notification.httpCustom.qs) {
          let qs = []
          for (const [key, value] of Object.entries(subsc.notification.httpCustom.qs)) {
            qs.push({ key: key, value: value })
          }
          qs.push({ key: null, value: null })
          subscribe.notification.http.qs = qs
        }
        if (subsc.notification.httpCustom.method) {
          subscribe.notification.http.method = subsc.notification.httpCustom.method
        }
        if (subsc.notification.httpCustom.payload) {
          subscribe.notification.http.payload = subsc.notification.httpCustom.payload
        }
      }
      if (subsc.notification && subsc.notification.attrsFormat) {
        subscribe.notification.attrsFormat = subsc.notification.attrsFormat
      }
      if (subsc.notification && subsc.notification.attrs) {
        subscribe.notification.attrs.values = subsc.notification.attrs
      }
      if (subsc.notification && subsc.notification.timesSent) {
        subscribe.notification.attrs.values = subsc.notification.attrs
      }
      // if (subsc.notification && subsc.notification.) {}
      // if (subsc.notification && subsc.notification.) {}
      // if (subsc.notification && subsc.notification.) {}
      if (subsc.expires) {
        subscribe.expires = subsc.expires
      }
      if (subsc.throttling) {
        subscribe.throttling = subsc.throttling
      }
      return subscribe
    },
    confirmCancel: function () {
    }
  },
  computed: {
    filterSubscriptionForm: {
      get: function () {
        const subscription = {
          expires: this.subscriptionForm.expires,
          subject: {
            entities: []
          },
          notification: {}
        }
        if (this.subscriptionForm.description) {
          subscription.description = this.subscriptionForm.description
        }
        if (this.subscriptionForm.throttling) {
          subscription.throttling = this.subscriptionForm.throttling
        }
        this.subscriptionForm.subject.entities.forEach(entitie => {
          if (entitie.idPattern && entitie.type) {
            subscription.subject.entities.push(entitie)
          }
        })
        let condition = {}
        let expreesion = {}
        if (this.subscriptionForm.subject.condition.attrs.length > 0) {
          Object.assign(condition, { attrs: this.subscriptionForm.subject.condition.attrs })
          this.subscriptionForm.subject.condition.expression.forEach(exp => {
            if (exp.key && exp.value) {
              Object.assign(expreesion, { [exp.key]: exp.value })
            }
          })
          subscription.subject.condition = Object.assign(condition, { expression: expreesion })
        }
        if (this.subscriptionForm.notification.protocol === 'http') {
          subscription.notification.http = {
            url: this.subscriptionForm.notification.http.url
          }
        } else if (this.subscriptionForm.notification.protocol === 'httpCustom') {
          let httpCustom = {}
          let headerObj = {}
          let qsObj = {}
          Object.assign(httpCustom, { url: this.subscriptionForm.notification.http.url })
          this.subscriptionForm.notification.http.headers.filter(header => header.key && header.value).forEach(header => {
            Object.assign(headerObj, { [header.key]: header.value })
          })
          this.subscriptionForm.notification.http.qs.filter(qsf => qsf.key && qsf.value).forEach(qsf => {
            Object.assign(qsObj, { [qsf.key]: qsf.value })
          })
          subscription.notification.httpCustom = Object.assign(httpCustom, { headers: headerObj }, { qs: qsObj })
        }
        if (this.subscriptionForm.notification.attrsFormat) {
          subscription.notification.attrsFormat = this.subscriptionForm.notification.attrsFormat
        }
        if (this.subscriptionForm.notification.attrs.values.length > 0) {
          subscription.notification.attrs = this.subscriptionForm.notification.attrs.values
        }
        if (this.subscriptionForm.notification.timesSent) {
          subscription.notification.timesSent = this.subscriptionForm.notification.timesSent
        }
        if (this.subscriptionForm.notification.lastNoti) {
          subscription.notification.lastNotification = this.subscriptionForm.notification.lastNoti
        }
        if (this.subscriptionForm.notification.lastFail) {
          subscription.notification.lastFailure = this.subscriptionForm.notification.lastFail
        }
        if (this.subscriptionForm.notification.lastSuccess) {
          subscription.notification.lastSuccess = this.subscriptionForm.notification.lastSuccess
        }
        return subscription
      },
      set: function (subsc) {
        const subscribe = JSON.parse(JSON.stringify(subscription))
        if (subsc.description) subscribe.description = subsc.description
        if (subsc.subject && subsc.subject.entities) subscribe.subject.entities = [...subsc.subject.entities, { idPattern: null, type: null }]
        if (subsc.subject && subsc.subject.condition && subsc.subject.condition.attrs) subscribe.subject.condition.attrs = subsc.subject.condition.attrs
        if (subsc.subject && subsc.subject.condition && subsc.subject.condition.expression) {
          let expression = []
          for (const [key, value] of Object.entries(subsc.subject.condition.expression)) {
            expression.push({ key: key, value: value })
          }
          expression.push({ key: null, value: null })
          subscribe.subject.condition.expression = expression
        }
        if (subsc.notification && subsc.notification.http) {
          subscribe.notification.protocol = 'http'
          if (subsc.notification.http.url) {
            subscribe.notification.http.url = subsc.notification.http.url
          }
        }
        if (subsc.notification && subsc.notification.httpCustom) {
          subscribe.notification.protocol = 'httpCustom'
          if (subsc.notification.httpCustom.url) {
            subscribe.notification.http.url = subsc.notification.httpCustom.url
          }
          if (subsc.notification.httpCustom.headers) {
            let headers = []
            for (const [key, value] of Object.entries(subsc.notification.httpCustom.headers)) {
              headers.push({ key: key, value: value })
            }
            headers.push({ key: null, value: null })
            subscribe.notification.http.headers = headers
          }
          if (subsc.notification.httpCustom.qs) {
            let qs = []
            for (const [key, value] of Object.entries(subsc.notification.httpCustom.qs)) {
              qs.push({ key: key, value: value })
            }
            qs.push({ key: null, value: null })
            subscribe.notification.http.qs = qs
          }
          if (subsc.notification.httpCustom.method) {
            subscribe.notification.http.method = subsc.notification.httpCustom.method
          }
          if (subsc.notification.httpCustom.payload) {
            subscribe.notification.http.payload = subsc.notification.httpCustom.payload
          }
        }
        if (subsc.notification && subsc.notification.attrsFormat) {
          subscribe.notification.attrsFormat = subsc.notification.attrsFormat
        }
        if (subsc.notification && subsc.notification.attrs) {
          subscribe.notification.attrs.values = subsc.notification.attrs
        }
        if (subsc.expires) {
          subscribe.expires = subsc.expires
        }
        if (subsc.throttling) {
          subscribe.throttling = subsc.throttling
        }
        this.subscriptionForm = subscribe
      }
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      this.$router.go(-1)
    }
  },
  created () {
    this.fetchSubscription()
  }
}
</script>
